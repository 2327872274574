



















import Vue, { Component, PropType } from 'vue'
import RadialProgressBar from 'vue-radial-progress'
import { EnergyComparisonResult } from '@typesCustom/index'

export default Vue.extend({
  components: {
    RadialProgressBar,
  },
  props: {
    result: {
      type: Object as PropType<EnergyComparisonResult>,
      required: true,
    },
  },
  computed: {
    level(): string {
      const { lvl } = this.result
      return lvl === 'neighbourhood' ? 'buurt' : lvl === 'municipality' ? 'gemeente' : 'provincie'
    },
    consumptionDifference(): number {
      const { total } = this.result.results
      return Math.round((1 - total) * 100)
    },
  },
  methods: {
    percentage(): string {
      if (this.consumptionDifference >= 20) {
        return 'ruim 15% minder'
      } else if (this.consumptionDifference >= 0) {
        return 'net 10% minder'
      } else if (this.consumptionDifference >= -20) {
        return 'ruim 10% meer'
      }
      return 'ruim 20% meer'
    },
    heading(): string {
      if (this.consumptionDifference >= 20) {
        return 'Top!'
      } else if (this.consumptionDifference >= 0) {
        return 'Goedzo!'
      } else if (this.consumptionDifference >= -20) {
        return 'Kan beter!'
      }
      return 'Oei!'
    },
    className(): string {
      return this.consumptionDifference < 0 ? 'has-text-danger' : 'has-text-primary'
    },
    color() {
      return this.consumptionDifference < 0 ? '#ff3860' : '#00d1b2'
    },
    steps() {
      return Math.abs(this.consumptionDifference)
    },
  },
})
