
































import { mapGetters } from 'vuex'
import { Entry } from 'contentful'
import ToolTip from './ToolTip.vue'
import BooleanInput from './BooleanInput.vue'
import Vue, { Component, PropType } from 'vue'
import EnergyComparison from './EnergyComparison.vue'
import { AnswerOptionContent, QuestionContent } from '@typesCustom/contentful'
import { Address, Answer, AnswerValue, CollectionOptions, EnergyComparisonResult, Question } from '@typesCustom/index'

export default Vue.extend({
  components: {
    ToolTip,
    BooleanInput,
    EnergyComparison,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<CollectionOptions>,
      required: true,
    },
    inputTypes: {
      type: Object as PropType<{ [key: string]: Component }>,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['displayText', 'helpText']),
    comparisonResult(): EnergyComparisonResult | {} {
      return this.$store.state.comparison
    },
    question(): QuestionContent {
      return this.$store.state.texts.questions[this.id]
    },
    answerOptions(): AnswerOptionContent[] {
      return this.question.options!.map((o: Entry<AnswerOptionContent>) => o.fields)
    },
    childQuestions(): Question[] {
      const keys = this.value
        ? Object.keys(this.options).filter((k: string) => k !== this.dontKnow)
        : [this.dontKnow, 'heatNetwork']

      return keys.reduce((acc: Question[], key: string) => {
        return [this.options[key], ...acc]
      }, [])
    },
    answers(): number[] {
      const ids = this.childQuestions.reduce((acc: string[], q: Question) => {
        return q.format === 'number' ? [...acc, q.id] : acc
      }, [])
      return this.$store.getters.answers.reduce((acc: number[], a: Answer) => {
        return a.hasOwnProperty('value') && a.value !== null && ids.includes(a.id) ? [...acc, Number(a.value)] : acc
      }, [])
    },
  },
  data: () => ({
    value: true as boolean,
    dontKnow: 'bill',
  }),
  methods: {
    getLabel(value: boolean): string {
      const option: AnswerOptionContent | undefined = this.answerOptions.find(
        (o: AnswerOptionContent) => o.value === String(value),
      )
      return option ? option.label : ''
    },
    handleChange({ id, value }: { id: string; value: AnswerValue }) {
      const name = Object.keys(this.options).find(key => this.options[key].id === id)
      if (name === 'heatNetwork') {
        const heatingId = this.options.heating.id
        // Deep copy
        const heatingQuestion = JSON.parse(JSON.stringify(this.$store.state.texts.questions[heatingId]))
        heatingQuestion.question = value ? 'Warmte' : 'Gas'
        heatingQuestion.options[0].fields.label = value ? 'GJ' : 'm3'
        this.$store.commit('UPDATE_UI_TEXT', { key: heatingId, question: heatingQuestion })
      }
    },
  },
  watch: {
    answers(newVal: number[], oldVal: number[]) {
      const newTotal = newVal.reduce((acc, cur) => acc + cur, 0)
      const oldTotal = oldVal.reduce((acc, cur) => acc + cur, 0)
      if (newVal[0] && newVal[1] && newTotal !== oldTotal) {
        setTimeout(() => {
          this.$store.dispatch('getEnergyComparison')
        }, 1000)
      }
    },
  },
})
