







import TextInput from './TextInput.vue'
import { AnswerValue } from '@typesCustom/index'

export default TextInput.extend({
  data() {
    return {
      value: false as AnswerValue,
    }
  },
  mounted() {
    if (this.required) {
      this.$store.dispatch('require', this.id)
      this.$store.dispatch('saveAnswer', { id: this.id, value: false })
    }
  },
  watch: {
    value(newVal: AnswerValue, oldVal: AnswerValue) {
      if (newVal !== oldVal) {
        this.$store.dispatch('saveAnswer', { id: this.id, value: this.value })
        this.$emit('change', { id: this.id, value: this.value })
      }
    },
  },
})
